<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :icon="$vuetify.breakpoint.smAndDown ? true : false"
        :dark="$vuetify.breakpoint.smAndDown ? true : false"
        :large="$vuetify.breakpoint.smAndDown ? true : false"
        :class="[$vuetify.breakpoint.smAndDown ? 'mr-2': 'mr-4']"
      >
        <div v-if="!$vuetify.breakpoint.smAndDown">Compartir</div>
        <v-icon :right="$vuetify.breakpoint.smAndDown ? false : true" :class="$vuetify.breakpoint.smAndDown ? 'mr-1': ''">fas fa-share-alt</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-btn
        v-for="({ icon, color, title, url }, index) in items"
        :key="index"
        icon
        :color="color"
        :title="title"
        :href="url"
        target="_blank"
        large
      ><v-icon>{{ icon }}</v-icon></v-btn>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    items: [
      { icon: 'fab fa-whatsapp', color:'#25D366', title: 'WhatsApp', url: 'https://api.whatsapp.com/send?text=https://rrhh.borigenbetzel.com.ar' },
      { icon: 'fab fa-facebook-square', color:'#3B5998', title: 'Facebook', url: 'http://www.facebook.com/share.php?u=https://rrhh.borigenbetzel.com.ar' },
      { icon: 'fab fa-linkedin', color:'#0E76A8', title: 'LinkedIn', url: 'https://www.linkedin.com/sharing/share-offsite/?url=https://rrhh.borigenbetzel.com.ar' },
    ],
  }),
}
</script>