var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:[_vm.$vuetify.breakpoint.smAndDown ? 'mr-2': 'mr-4'],attrs:{"icon":_vm.$vuetify.breakpoint.smAndDown ? true : false,"dark":_vm.$vuetify.breakpoint.smAndDown ? true : false,"large":_vm.$vuetify.breakpoint.smAndDown ? true : false}},'v-btn',attrs,false),on),[(!_vm.$vuetify.breakpoint.smAndDown)?_c('div',[_vm._v("Compartir")]):_vm._e(),_c('v-icon',{class:_vm.$vuetify.breakpoint.smAndDown ? 'mr-1': '',attrs:{"right":_vm.$vuetify.breakpoint.smAndDown ? false : true}},[_vm._v("fas fa-share-alt")])],1)]}}])},[_c('v-card',_vm._l((_vm.items),function(ref,index){
var icon = ref.icon;
var color = ref.color;
var title = ref.title;
var url = ref.url;
return _c('v-btn',{key:index,attrs:{"icon":"","color":color,"title":title,"href":url,"target":"_blank","large":""}},[_c('v-icon',[_vm._v(_vm._s(icon))])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }