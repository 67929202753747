<template>
  <v-row class="d-flex justify-center">
    <v-col cols="12" md="11" xl="9" :class="$vuetify.breakpoint.xl ? 'mx-1' : ''">
      <v-img
        src="../assets/people.webp"
        class="my-n3"
        :height="$vuetify.breakpoint.xs ? '30vh' : '45vh'"
        style="border-bottom-right-radius:5px; border-bottom-left-radius:5px"
      >
        <v-row style="height: 100%">
          <v-col cols="12" sm="2"  md="4" :class="$vuetify.breakpoint.xs ? 'mb-n2': ''"></v-col>
          <v-col cols="12" sm="10" md="8" class="d-flex justify-end pr-4" v-if="!$vuetify.breakpoint.xs">
            <v-img
              v-for="(item, index) in items"
              :key="index"
              :src="item.src"
              :width="item.size"
              contain
              class="mx-2"
            />
          </v-col>
          <v-col cols="12" class="d-flex justify-start white--text flex-wrap" :class="[$vuetify.breakpoint.xs ? 'py-0 align-start': 'align-end']">
            <v-avatar :size="$vuetify.breakpoint.xs ? 100 : 150" class="ml-2" style="box-shadow: 1px 1px 4px #000000"><img src="../assets/Logo Recursos Humanos.webp" ></v-avatar>
            <v-spacer v-if="$vuetify.breakpoint.xs"></v-spacer>
            <Compartir v-if="$vuetify.breakpoint.xs" />
            <v-row no-gutters :style="$vuetify.breakpoint.smAndUp ? 'width: 50%' : ''" :class="[$vuetify.breakpoint.xs ? 'ml-2': 'ml-4']">
              <v-col cols="12" :class="$vuetify.breakpoint.xs ? 'mb-n1': ''">
                <span
                  :class="[$vuetify.breakpoint.xs ? 'display-1': 'display-3']"
                  class="font-weight-black"
                  style="text-shadow: 1px 1px 4px #000000"
                >
                  Borigen Betzel
                </span>
              </v-col>
              <v-col cols="12">
                <span
                  :class="[$vuetify.breakpoint.xs ? 'text-h6' : 'display-1']"
                  class="font-weight-light"
                  style="text-shadow: 0 0 4px #000000"
                >
                  Oportunidades de empleo
                </span>
              </v-col>
            </v-row>
          <Compartir v-if="!$vuetify.breakpoint.xs" />
          </v-col>
        </v-row>
      </v-img>
    </v-col>
    <v-col cols="12" md="4" xl="3">
      <v-sheet rounded="lg">
        <v-expansion-panels class="pa-1" v-model="panel" flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-h5 font-weight-light">
              <div><v-icon left class="mb-1 ml-n1">fas fa-filter</v-icon>Filtrar</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form ref="form">
                <div>
                  <v-text-field
                    v-model.trim="palabras_claves"
                    label="Palabras claves"
                  ></v-text-field>
                  <v-select
                    v-model="provincias_seleccionadas"
                    :items="provincias"
                    :rules="[rules.max]"
                    attach
                    chips
                    label="Provincia"
                    multiple
                  ></v-select>
                </div>
                <div class="d-flex justify-center mt-2">
                  <v-btn
                    class="col-6 mr-2"
                    color="orange"
                    dark
                    @click="limpiar"
                  >
                    Limpiar
                  </v-btn>
                  <v-btn
                    class="col-6 ml-2"
                    color="info"
                    @click="buscar"
                  >
                    Buscar
                    <v-icon right small>fas fa-search</v-icon>
                  </v-btn>
                </div>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-sheet>
    </v-col>

    <v-col md="7" xl="6">
      <v-sheet
        min-height="70vh"
        rounded="lg"
      >
        <v-col><h1 class="text-h4 font-weight-light">Búsquedas activas</h1></v-col>
        <v-col>
          <div v-if="load" class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <div v-else-if="convocatorias.length > 0">
            <v-list class="pt-0">
              <v-list-item
                v-for="(item, index) in convocatorias"
                :key="index"
                class="py-1 px-4"
              >
                <ConvocatoriaCard :home="true" :item="item" />
              </v-list-item>
            </v-list>
          </div>
          <div v-else class="d-flex justify-center">
            <v-alert
              border="left"
              outlined
              dense
              :type="color"
            >
              {{ message }}
            </v-alert>
          </div>
        </v-col>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { db } from '../firebase'
import ConvocatoriaCard from '../components/ConvocatoriaCard'
import Compartir from '../components/utils/Share'

export default {
  data() {
    return {
      panel: 0,
      load: false,
      palabras_claves: '',
      message: '',
      color: 'info',
      provincias: [],
      provincias_seleccionadas: [],
      items: [
        { src: require('../assets/rfb.webp'), size: '8vh' },
        { src: require('../assets/litech.webp'), size: '6vh' },
        { src: require('../assets/rada.webp'), size: '4vh' },
        { src: require('../assets/dycar.webp'), size: '7vh' },
        { src: require('../assets/chevrolet.webp'), size: '8vh' },
        { src: require('../assets/citroen.webp'), size: '10vh' },
        { src: require('../assets/usados.webp'), size: '13vh' },
      ],
      rules: { max: value => value.length <= 10 || 'Máximo 10 provincias' },
    }
  },
  async created() {
    this.get_convovatorias()
    await db.collection('provincias').orderBy('nombre').get()
      .then((query) => {
        query.forEach((doc) => {
          this.provincias.push(doc.data().nombre)
        })
      })
      .catch((error) => {
        console.log(error)
      })
  },
  components: {
    ConvocatoriaCard, Compartir
  },
  computed: {
    ...mapState(['convocatorias', 'user']),
    ...mapGetters(['exist_user'])
  },
  methods: {
    ...mapActions(['set_convocatorias']),

    async get_convovatorias() {
      this.load = true
      let convocatorias = []
      let query = db.collection('convocatorias').where('activa', '==', true)
        .where('fecha.hasta', '>=', new Date().toISOString().substr(0, 10))

      if (this.provincias_seleccionadas.length > 0) query = query.where('provincia', 'in', this.provincias_seleccionadas)

      await query.get()
        .then((res) => {
          res.forEach((doc) => {
            let convocatoria = doc.data()
            convocatoria.id = doc.id
            convocatoria.fecha_desde_format = this.formatDate(convocatoria.fecha.desde)
            convocatoria.fecha_hasta_format = this.formatDate(convocatoria.fecha.hasta)
            if (convocatoria.pasantia.value) {
              if (this.exist_user) {
                if (convocatoria.pasantia.pasantes.find(pasante => pasante.dni.toString() === this.user.documento)) {
                  convocatorias.push(convocatoria)
                }
              }
            } else {
              convocatorias.push(convocatoria)
            }
          })
        })
        .catch((error) => {
          console.log(error)
        })

      convocatorias = convocatorias.filter(this.filter_palabras_claves)
      convocatorias = convocatorias.sort((a, b) => new Date(b.fecha.desde).getTime() - new Date(a.fecha.desde).getTime())

      if (this.provincias_seleccionadas.length === 0 && this.palabras_claves === '') {
        this.message = 'Actualmente no hay búsquedas activas disponibles'
        this.color = 'info'
      } else {
        this.message = 'No se encontraron búsquedas activas para los filtros seleccionados'
        this.color = 'warning'
      }

      this.set_convocatorias(convocatorias)
      this.load = false
    },

    buscar () {
      if (this.$refs.form.validate()) {
        this.get_convovatorias()
      }
    },

    filter_palabras_claves(convocatoria) {
      const palabras_claves = this.palabras_claves.toLowerCase()
      const busqueda = convocatoria.busqueda.toLowerCase()
      const puesto = convocatoria.puesto.text.toLowerCase()

      return busqueda.indexOf(palabras_claves) >= 0 || puesto.indexOf(palabras_claves) >= 0
    },

    limpiar() {
      this.palabras_claves = ''
      this.provincias_seleccionadas = []
    },

    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
  }
}
</script>